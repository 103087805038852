<template>
  <div class="main-contents code">
    <div class="tit">약관관리</div>
    <div class="search-box">
        <!-- <SelectComp :list="loadMemberList" v-model="srchFilter.cdNm" title="회원구분" /> -->
        <!-- <SelectComp class="wd01" v-model="srchFilter.mberDivCd" :list="loadMemberList" :isAll="true" title="전체" /> -->
        <SelectComp class="wd01" list="Y:필수,N:선택" v-model="srchFilter.mandYn" :isAll="true" title="필수여부" />
        <nbsp/>
        <!-- <InputComp v-model="srchFilter.srchTxt" placeholder="약관명" @keyup.enter="getProvisionList('1')"/> -->
        <SelectComp  cdId="PRO140" v-model="srchFilter.stipDivCd" :isAll="true" title="약관명" />
        <nbsp/>
        <SelectComp class="wd01" list="Y:예,N:아니오" v-model="srchFilter.useYn" :isAll="true" title="사용여부" />
        <nbsp/>
        <img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getProvisionList('1')"/>
        <div class="sub_btn" @click="$router.push('MAN932M02')">
          +등록
      </div>
    </div>
    <!--  약관 리스트    -->
      <div class="Board type3">
        <table class="Board_type3 admin">
          <colgroup>
             <col width="4%">
             <col width="10%">
             <col width="57%">
             <col width="5%">
             <col width="14%">
             <col width="10%">
          </colgroup>
          <thead>
            <tr>
              <th>NO</th>
              <th>필수여부</th>
              <th>약관명</th>
              <th>버전</th>
              <th>등록일자</th>
              <th>사용여부</th>
            </tr>
          </thead>
          <tbody v-if="provisionList.length>0">
          <!-- 최대 25건 조회 -->
            <tr v-for="(row,rowIdx) in provisionList" :key="rowIdx">
              <td class="score">{{(pageInfo.pageUnit * (pageInfo.pageIndex-1)) + rowIdx+1}}</td>
              <!-- <td class="score"> <SelectComp type="text" :value="row.mberDivCd" cdId="PRO101"/></td> -->
              <td class="score">
                <SelectComp type="text" :value="row.mandYn" list="Y:필수,N:선택"/>
              </td>
              <td class="score btn_cursor" @click="modify(row.stipDivCd, row.stipNm, row.stipVer)">{{row.stipNm}} &#9654;</td>
              <td class="score">{{row.stipVer}}</td>
              <td class="score">{{row.regDate | date('yyyy.MM.dd')}}</td>
              <td class="score">
                <SelectComp type="text" :value="row.useYn" list="Y:예,N:아니오"/>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
          <!--   약관 데이터 없는 경우  -->
          <tr>
            <td colspan="6" class="none">등록된 약관이 없습니다!</td>
          </tr>
          </tbody>
        </table>
      </div>
      <!-- 페이징부분 -->
      <pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
  </div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';

export default {
  data(){
    return{
      //검색 조건
      srchFilter:{
        // srchTxt: '',
        stipDivCd : '',
        pageIndex: 1,
        useYn: '',
        mandYn: '',
      },
      //약관 목록
      provisionList : [],
      //페이징
      pageInfo : {},

    }
  },

  components: {
    pagingComp
  },

  beforeMount(){
    this.memberList();
  },

  mounted() {

    var keys = Object.keys(this.srchFilter);
		for(var key in this.$route.params){
			if(keys.indexOf(key) >= 0){
				this.srchFilter[key] = this.$route.params[key];
			}
		}

		this.getProvisionList()
  },
  methods:{
    //약관 목록 조회
    getProvisionList(div){
      var param = this.srchFilter;
      param.pageUnit = '25';
      param.pageSize = '10';
      if(div) param.pageIndex = 1;

      // console.log('getProvisionList PARAM',param);

      this.$.httpPost('/api/main/adm/provis/getProvisionList',param)
        .then(res=>{
          // console.log('getProvisionList RESULT',res);

          this.provisionList = res.data.provisionList;
          this.pageInfo = res.data.pageInfo;
        }).catch(this.$.httpErrorCommon);
    },

    //페이징 이동
    goPage(page) {
			this.srchFilter.pageIndex = page;
			this.getProvisionList();
		},

    //회원 구분 조회
    // memberList(){
    //   this.$.httpPost('/api/main/adm/provis/loadMemberList')
    //         .then(res => {
    //             console.log('loadMemberList RESULT', res);
    //             this.loadMemberList = res.data.loadMemberList;
    //         }).catch(this.$.httpErrorCommon);
    //   },

      modify(stipDivCd, stipNm, stipVer){
        this.$router.push({name: 'MAN932M02', params: {stipDivCd:stipDivCd, stipNm:stipNm, stipVer :stipVer}});
      }
		
  }
}
</script>